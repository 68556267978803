<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <p class="p-title">基础信息</p>
            <el-form ref="form" v-bind:rules="rules" v-bind:model="form" label-position="left" label-suffix=":" size="large" label-width="110px" class="dialog_box">
                <el-form-item label="秒杀活动名称" prop="title">
                    <el-input v-model="form.title" placeholder="请输入活动名称" style="margin-bottom:10px;"></el-input>
                </el-form-item>
              <el-form-item label="活动副标题" prop="title">
                <el-input v-model="form.subtitle" placeholder="请输入秒杀活动副标题" style="margin-bottom:10px;"></el-input>
              </el-form-item>
                <el-form-item label="位置" prop="position">
                    <el-select v-model="form.position" placeholder="请选择位置" style="margin-bottom:10px;">
                        <el-option v-for="items in positionList" :key="items.index" :label="items.name" :value="items.key"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="起止时间" prop="time">
                    <el-date-picker ref="drawDate" :picker-options="pickerOptions" style="margin-bottom:10px;" v-model="form.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>


                <el-form-item label="活动封面" prop="pic">
                    <Imgupload v-model="thumbs" :ImgVisible="ImgVisible" @changeImg="changeImg" @changetext="changetext" />
                    <span class="span_gray">建议尺寸750*750像素,可拖动图片调整位置</span>
                </el-form-item>
              <el-form-item label="排序" prop="r">
                <el-input v-model="form.r" placeholder="请输入排序值" style="margin-bottom:10px;"></el-input>
              </el-form-item>
              <el-form-item label="状态" prop="state">
                <el-radio-group v-model="form.state">
                  <el-radio :label="2">上线</el-radio>
                  <el-radio :label="1">下线</el-radio>
                  <el-radio :label="3">已下架</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="开场时间&结束时间(整点)" >
                <el-checkbox-group v-model="checkedHoures" @change="handleCheckedHoursChange"  :min="0"  :max="2" width="300px">
                  <el-checkbox v-for="hour in hourList" :label="hour" :key="hour">{{ hour }}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="saveData">保 存</el-button>
                    <el-button @click="cancel">取 消</el-button>
                </el-form-item>
                <!-- 选择图片对话框 -->
                <div class="img">
                    <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import loadEvents from '@/utils/loading'
import Imgupload from '@/components/Imgupload';
import {deal} from '@/common/main';
import {request} from '@/common/request';
import bus from '@/common/bus';
import Picture from '@/components/Picture';

let loads = new loadEvents();
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Imgupload,
        Picture
    },
    data() {
        return {
            ImgVisible: false,
            text: 'image',
            pic: "",
            id: null,
            isIndeterminate: true,
            checkedHoures: [0, 1],
            mode: 'edit',
            thumbs:[],
            hourList:[0,1,2,3,4, 5,6,7,8, 9,10, 11,12,
              13,14, 15,16,17,18, 19,20,21,22, 23,],
            menuList: ['营销', '秒杀专场','编辑'],
            loading: false,
            rules: {
                name: [{
                    required: true,
                    message: '活动名称不能为空',
                    trigger: 'blur'
                }],
                time: [{
                    required: true,
                    message: '活动时间不能为空',
                    trigger: 'blur'
                }],
                logo: [{
                    required: true,
                    message: 'logo不能为空',
                    trigger: ['blur', 'change']
                }],
                pic: [{
                    required: true,
                    message: '专场展示图片不能为空',
                    trigger: ['blur', 'change']
                }],
                poster: [{
                    required: true,
                    message: '海报不能为空',
                    trigger: ['blur', 'change']
                }],
                image: [{
                    required: true,
                    message: '专场封面图不能为空',
                    trigger: ['blur', 'change']
                }],
                state: [{
                    required: true,
                    message: '请选择状态',
                    trigger: 'blur'
                }],

            },
            mode: 'add',
            form: {

            },
            header: {},
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },

            positionList: [],
            acceptRulers: {
                number: [{
                    required: true,
                    message: '件数不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[0-9]*$',
                    message: "只能输入数字"
                }],
                price: [{
                    required: true,
                    message: '件数不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                    message: "只能输入整数或小数点后两位数字"
                }],
            }
        };
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        deal.getconf('seckill', this)
    },
    watch: {
        $route: {
            handler(to, from) {
                if (to.query.id && to.query.id !== this.id && to.path === "/activity-SeckillSessionEdit") {
                    this.id = to.query.id
                    this.getDetail(this.id);
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        // 获取详情数据
        getDetail(id) {
            request.get('/goods/seckill/session/detail', { id: id }).then(ret => {
                if (ret.code == 1) {
                    deal.getconf('seckill', this)
                    let list = ret.data.list;
                    let time = [];
                    time[0] = deal.timestamp(list.start_day, 'unix');
                    time[1] = deal.timestamp(list.end_day, 'unix');
                    this.form = ret.data.list;
                    this.checkedHoures = [list.stime, list.etime]
                    this.thumbs.push(ret.data.list.pic)
                    this.form.tyList = list.list
                    this.$set(this.form, 'time', time)
                    this.$nextTick(function () {
                        this.$refs.form.clearValidate();
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                      console.log("--------deal.timestamp(this.form.time[0])")
                      console.log(deal.timestamp(this.form.time[0]))
                        this.form.start_day = deal.timestamp(this.form.time[0]);
                        this.form.end_day = deal.timestamp(this.form.time[1] );
                        //处理时间
                        console.log(5555, this.form)
                        this.form.pic=this.thumbs[0]?this.thumbs[0]:""
                      console.log("------------------------")
                      console.log(this.checkedHoures.length)
                      console.log(this.checkedHoures)
                      if (this.checkedHoures.length<2){
                        this.$message.error("开场时间&结束时间为必选项 ");
                        return  false
                      }
                      if (this.checkedHoures[0]<this.checkedHoures[1]){
                        this.form.stime=this.checkedHoures[0]
                        this.form.etime=this.checkedHoures[1]
                      }else {
                        this.form.stime=this.checkedHoures[1]
                        this.form.etime=this.checkedHoures[0]
                      }
                        request.post('/goods/seckill/session/edit', this.form).then(ret => {
                            if (ret.code == 1) {
                                this.$message.success('编辑成功');
                                this.$refs.form.clearValidate()
                                bus.$emit('close_current_tags');
                                this.$router.push({ path: '/activity-SeckillSessionList' })
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 取消按钮
        cancel() {
            bus.$emit('close_current_tags');
            this.$router.push({ path: '/activity-SeckillSessionList' })
        },
        // 添加规格
        addType() {
            this.form.tyList.push({
                number: null,
                price: null
            })
            this.$forceUpdate()
        },
        //
        handleCheckedHoursChange(val,before){
        },

        // 删除规格
        deleteTyList(index) {
            this.form.tyList.splice(index, 1)
            this.$forceUpdate()
        },
        // 图片上传之前
        beforeLogoUpload(file) {
            loads.open(`.img1 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        beforeBannerUpload(file) {
            loads.open(`.img2 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        beforePosterUpload(file) {
            loads.open(`.img3 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        // 图片上传成功
        handleLogoSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'logo', res.data.uri);
            }
        },
        handleBannerSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'image', res.data.uri);
            }
        },
        handlePosterSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'poster', res.data.uri);
            }
        },
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;

        },
        choiceImg(text) {
            this.text = text
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            if (this.text === 'many') {
                if (this.thumbs.length < 9) {
                    this.thumbs.push(url)
                }
                else {
                    this.$message({ type: 'error', message: '最多上传九张图片' })
                }
            } else {
                this.$set(this.form, this.text, url);
            }
        },
        changeImg(val) {
            this.ImgVisible = val
        },
        changetext(val) {
            this.text = val
        },
    }
};
</script>

<style scoped>
.type {
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
}
.type_box {
    display: flex;
}
.box {
    width: 240px;
    padding: 20px 30px 0;
    border: 1px solid #eeeeee;
    margin-right: 20px;
}
.box >>> .el-form-item {
    margin-bottom: 20px;
}
.type_box .box >>> .el-input--large .el-input__inner {
    width: 150px;
    margin-right: 10px;
}
.el-textarea >>> .el-textarea__inner {
    width: 500px !important;
    height: 200px;
}
.p-title {
    font-size: 20px;
    font-weight: 900;
}
.dialog_box {
    padding: 30px;
}
.span_gray {
    font-size: 12px;
    color: gray;
}
.dialog_box >>> .el-select .el-input--large .el-input__inner {
    width: 400px;
}
.dialog_box >>> .el-input--large .el-input__inner {
    width: 201%;
}
.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}
.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}
.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

.dialog_box .up {
    margin-top: 20px;
}
.dialog_box .img1 .avatar-uploader-icon {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.dialog_box .img1 .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.dialog_box .img2 .avatar-uploader-icon {
    font-size: 28px;
    width: 150px;
    height: 60px;
    line-height: 60px;
}
.dialog_box .img2.avatar {
    font-size: 28px;
    width: 150px;
    height: 60px;
    line-height: 60px;
}
.dialog_box .img3 .avatar-uploader-icon {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.dialog_box .img3.avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}

.container {
    background: #ffffff;
}

.form-input >>> .el-input__inner {
    width: 500px;
}

.el-input--small .el-input__inner {
    width: 500px;
}
/* 选择链接样式 */
.dialog_box .el-button {
    margin-left: 220px;
}
.type_box .el-button {
    margin-left: 20px;
}
</style>